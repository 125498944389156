import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import './bottomfooter.css';
import transparentLogo from './Images/logoTransparent.png';
import ScheduleDemoPopup from './ScheduleDemoPopup';
import paymentMethodsImage from './Images/paymetMethodsIcon.png';
import bottomFooterSocialIcons from './Images/bottomFooterSocialIcons.png';
import appStoreDownloadIcon from './Images/appStoreDownloadIcon.png';
import paymentMethods from './Images/paymentMethods.png';
import roasberryLinkedin from './Images/roasberryLinkedin.png';
import roasberryInsta from './Images/roasberryInsta.png';
import roasberryX from './Images/roasberryX.png';
import shopifyAppStore from "./Images/shopifyAppStore2.png";
import footerLogo from "./footerLogo.png";

import BTXicon from "./BTXicon.png";
import BTInstaIcon from "./BTInstaIcon.png";
import BTLinkedinIcon from "./BTLinkedinIcon.png";

import newPaymentBT from "./newPaymentBT.png"

import arrowUp from "./arrow.up.png";
import arrowDown from "./arrow.down.png";

import ScrollToTop from './ScrollToTop'; // Import the ScrollToTop component

const BottomFooter = () => {
    const { t } = useTranslation(); // Initialize useTranslation hook
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const [isProductFeaturesOpen, setIsProductFeaturesOpen] = useState(false);
    const [isCompanyOpen, setIsCompanyOpen] = useState(false);
    const [isMediaOpen, setIsMediaOpen] = useState(false);
    const [isRoasberryVsOpen, setIsRoasberryVsOpen] = useState(false);
    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const openPDF = (pdfPath) => {
        window.open(pdfPath, '_blank');
    };

    const privacyPolicyPath = '/PRIVACY_POLICY.pdf';
    const refundPolicyPath = '/REFUND_POLICY.pdf';

    const subscribeToMailchimp = async (email) => {
        try {
            const response = await axios.post('https://<YOUR_MAILCHIMP_SERVER>.api.mailchimp.com/3.0/lists/<YOUR_LIST_ID>/members', {
                email_address: email,
                status: 'subscribed'
            }, {
                auth: {
                    username: 'anystring', // should be any string followed by your Mailchimp API key
                    password: '<YOUR_MAILCHIMP_API_KEY>'
                }
            });
            console.log('Subscription successful:', response.data);
            // Handle success, e.g., show a success message to the user
        } catch (error) {
            console.error('Subscription failed:', error.response.data);
            // Handle failure, e.g., show an error message to the user
        }
    };

    const handleSubscribe = () => {
        const email = // get the user's email address from wherever it's stored in your application
            subscribeToMailchimp(email);
    };

    return (
        <>

            <footer className="max-md:hidden w-11/12 flex flex-col mx-auto gap-6 items-start mt-3 mb-3">
                <div className="w-full flex flex-row items-center justify-between ">
                    <img src={footerLogo} className='w-[200px] h-[52px]' />
                    <a
                        href="https://www.producthunt.com/posts/roasberry?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-roasberry"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=488960&theme=dark"
                            alt="Roasberry - Finally, an accurate, intuitive, and affordable pixel tracker. | Product Hunt"
                            style={{ width: '240px', height: '52px' }}
                            width="250"
                            height="54"
                        />
                    </a>
                </div>
                <div className='w-full mx-auto flex flex-row justify-between items-start max-md:flex max-md:flex-col'>
                    <div className="flex flex-col  h-full items-left gap-3">
                        <p className='text-[20px] text-bold text-white'>Product Features</p>
                        <p className=" text-[16px] max-md:text-[12px]" >Dashboard and Insight Tools</p>
                        <p className=" text-[16px] max-md:text-[12px]" >Advanced Pixel Tracking</p>
                        <p className=" text-[16px] max-md:text-[12px]" >Visitor Log - Insights </p>
                        <p className=" text-[16px] max-md:text-[12px]" >Unlimited Device Access</p>
                        <p className=" text-[16px] max-md:text-[12px]" >Mobile App Access</p>
                    </div>
                    <div className="flex flex-col  h-full items-left gap-3">
                        <p className='text-[20px] text-bold text-white'>Company</p>
                        <Link to="/Resources?scrollTo=aboutUs">
                            <p className="text-[16px] max-md:text-[12px]">{t('About Us')}</p>
                        </Link>
                        <Link to="https://www.linkedin.com/company/roasberry/jobs/">
                            <p className=" text-[16px] max-md:text-[12px]">{t('careers')}</p>
                        </Link>
                        <Link onClick={() => openPDF(refundPolicyPath)}>
                            <p className=" text-[16px] max-md:text-[12px]">{t('refundPolicy')}</p>
                        </Link>
                        <Link onClick={() => openPDF(privacyPolicyPath)}>
                            <p className=" text-[16px] max-md:text-[12px]">{t('privacyPolicy')}</p>
                        </Link>
                        <Link to={"/Pricing"}>
                            <p className=" text-[16px] max-md:text-[12px]">{t('pricing')}</p>
                        </Link>
                    </div>
                    <div className="flex flex-col h-full items-left gap-3">
                        <p className='text-[20px] text-bold text-white'>Media</p>
                        <Link to="https://www.youtube.com/@roasberry">
                            <p className=" text-[16px] max-md:text-[12px]">{t('Youtube Channel')}</p>
                        </Link>
                        <Link to="/Resources">
                            <p className=" text-[16px] max-md:text-[12px]">{t('Resources')}</p>
                        </Link>
                        <Link to="/Resources">
                            <p className=" text-[16px] max-md:text-[12px]">{t('Blog')}</p>
                        </Link>
                    </div>
                    <div className="flex flex-col h-full items-left gap-3">
                        <p className='text-[20px] text-bold text-white m-0 p-0'>Roasberry Vs</p>
                        <Link to="/comparison/1" className="m-0 p-0 text-[16px] max-md:text-[12px]">Roasberry vs Triple Whale</Link>
                        <Link to="/comparison/3" className="m-0 p-0 text-[16px] max-md:text-[12px]">Roasberry vs Polar Analytics</Link>
                        <Link to="/comparison/2" className="m-0 p-0 text-[16px] max-md:text-[12px]">Roasberry vs Northbeam</Link>
                        <Link to="/comparison/4" className="m-0 p-0 text-[16px] max-md:text-[12px]">Roasberry vs UpStackified</Link>
                        <Link to="/comparison/5" className="m-0 p-0 text-[16px] max-md:text-[12px]">Roasberry vs Kendall</Link>
                        <Link to="/comparison/6" className="m-0 p-0 text-[16px] max-md:text-[12px]">Roasberry vs Converge</Link>
                    </div>
                </div>
                <div className="flex flex-row w-full mx-auto h-full  gap-4 max-md:w-11/12 max-md:justify-center max-md:gap-1 max-md:mt-10 max-md:ml-3">
                    <div className='flex flex-col items-start w-1/3 gap-4'>
                        <div className="flex flex-row w-full mt-4 justify-between items-center">
                            <a href="https://apps.shopify.com/roasberry" target="_blank" rel="noopener noreferrer">
                                <img src={shopifyAppStore}
                                    className="object-fit w-full h-[56px] max-md:w-3/4"
                                    alt="Shopify App Store" />
                            </a>
                            <Link to="https://www.instagram.com/roasberrycom?igsh=MXA1enZhOXZpd2xhdg==" >
                                <img src={BTInstaIcon} className="object-fill w-[44px] h-fit max-md:w-3/4" />
                            </Link>
                            <Link to="https://x.com/roasberrycom?s=21&t=QuKCweFoy8SdG0L9_k8zDA" >
                                <img src={BTXicon} className="object-fit w-[44px] h-[44px] max-md:w-3/4" />
                            </Link>
                            <Link to="https://www.linkedin.com/company/roasberry/" >
                                <img src={BTLinkedinIcon} c className="object-fill w-[44px] h-[44px] max-md:w-3/4" />
                            </Link>
                        </div>
                        <img src={newPaymentBT} className="h-fit w-full object-cover" draggable="false" />

                    </div>
                    <div className='w-2/3 flex flex-row p-3 border border-[#2B2B2B] rounded-[12px]'>
                        <div className='flex flex-col w-3/5'>
                            <p className='text-[20px]'>{t('subscribeToNewsletter')} <span className="italic" style={{ color: "#4ED98B" }}>Roasberry’s Newsletter!</span></p>
                            <p className='max-md:text-[12px]'>{t('newsletterDescription')}</p>
                        </div>
                        <div className="w-1/3 flex flex-col justify-between">
                            <input className="ml-2 bg-transparent w-full px-4 py-2.5 rounded-[8px] text-white text-center text-[13px] border border-white"
                                placeholder={t('enterEmail')}
                            />
                            <button className="ml-2 w-full bg-[#CF0A54] px-4 py-2.5 rounded-[8px] text-white text-[13px]" onClick={() => {
                                window.location.href = "https://roasberry.us8.list-manage.com/subscribe/post?u=5bcd0037f27faac091c83af7e&amp;id=d4c38291bd&amp;f_id=00afc8e3f0";
                            }}>
                                {t('subscribe')}
                            </button>
                        </div>
                    </div>
                </div>
                <p className='text-[#797979] text-[14px] '>© 2024 Roasberry Inc. All rights reserved.</p>
                {isPopupOpen && <ScheduleDemoPopup onClose={togglePopup} />}
            </footer >

            <footer className="md:hidden bg-black text-white w-11/12 mx-auto">
                <div className="container mx-auto flex flex-col items-start">
                    <img src={footerLogo} alt="Roasberry Logo" className="w-[200px] h-[52px] mb-4" />

                    {/* Product Features Section */}
                    <div className="w-full bg-none">
                        <button
                            onClick={() => setIsProductFeaturesOpen(!isProductFeaturesOpen)}
                            className="w-full text-left text-[20px] font-bold flex justify-between items-center border-none outline-none m-1"
                        >
                            Product Features
                            <img src={isProductFeaturesOpen ? arrowUp : arrowDown} className='w-[24px] h-[24px]' />
                        </button>
                        {isProductFeaturesOpen && (
                            <div className="flex flex-col m-0">
                                <p className="m-0 p-1 text-[16px]">Dashboard and Insight Tools</p>
                                <p className="m-0 p-1 text-[16px]">Advanced Pixel Tracking</p>
                                <p className="m-0 p-1 text-[16px]">Visitor Log - Insights</p>
                                <p className="m-0 p-1 text-[16px]">Unlimited Device Access</p>
                                <p className="m-0 p-1 text-[16px]">Mobile App Access</p>
                            </div>
                        )}
                    </div>


                    {/* Company Section */}
                    <div className="w-full mt-4">
                        <button
                            onClick={() => setIsCompanyOpen(!isCompanyOpen)}
                            className="w-full text-left text-[20px] font-bold flex justify-between items-center border-none outline-none m-1"
                        >
                            Company
                            <img src={isCompanyOpen ? arrowUp : arrowDown} className='w-[24px] h-[24px]' />
                        </button>
                        {isCompanyOpen && (
                            <div className="flex flex-col m-0">
                                <Link to="/Resources"
                                    className="m-0 p-1">
                                    {t('About Us')}
                                </Link>
                                <Link to="https://www.linkedin.com/company/roasberry/jobs/"
                                    className="m-0 p-1">
                                    {t('careers')}
                                </Link>
                                <Link onClick={() => openPDF(refundPolicyPath)}
                                    className="m-0 p-1">
                                    {t('refundPolicy')}
                                </Link>
                                <Link onClick={() => openPDF(privacyPolicyPath)}
                                    className="m-0 p-1">
                                    {t('privacyPolicy')}
                                </Link>
                                <Link to="/Pricing"
                                    className="m-0 p-1">
                                    <p>{t('pricing')}</p>
                                </Link>
                            </div>
                        )}
                    </div>

                    {/* Media Section */}
                    <div className="w-full mt-4">
                        <button
                            onClick={() => setIsMediaOpen(!isMediaOpen)}
                            className="w-full text-left text-[20px] font-bold flex justify-between items-center border-none outline-none m-1"
                        >
                            Media
                            <img src={isMediaOpen ? arrowUp : arrowDown} className='w-[24px] h-[24px]' />
                        </button>
                        {isMediaOpen && (
                            <div className="flex flex-col m-0">
                                <Link to="https://www.youtube.com/channel/roasberry"
                                    className="m-0 p-1">
                                    {t('Youtube Channel')}
                                </Link>
                                <Link to="/Resources"
                                    className="m-0 p-1">
                                    {t('Resources')}
                                </Link>
                                <Link to="/Resources"
                                    className="m-0 p-1">
                                    {t('Blog')}
                                </Link>
                            </div>
                        )}
                    </div>

                    {/* Roasberry Vs Section */}
                    <div className="w-full mt-4 h-fit">
                        <button
                            onClick={() => setIsRoasberryVsOpen(!isRoasberryVsOpen)}
                            className="w-full text-left text-[20px] font-bold flex justify-between items-center border-none outline-none m-0 p-1"
                        >
                            Roasberry Vs
                            <img src={isRoasberryVsOpen ? arrowUp : arrowDown} className='w-[24px] h-[24px]' />
                        </button>
                        {isRoasberryVsOpen && (
                            <div className="flex flex-col h-fit">
                                <Link to="/comparison/1" className="m-0 p-1 text-[16px] max-md:text-[12px]">Roasberry vs Triple Whale</Link>
                                <Link to="/comparison/3" className="m-0 p-1 text-[16px] max-md:text-[12px]">Roasberry vs Polar Analytics</Link>
                                <Link to="/comparison/2" className="m-0 p-1 text-[16px] max-md:text-[12px]">Roasberry vs Northbeam</Link>
                                <Link to="/comparison/4" className="m-0 p-1 text-[16px] max-md:text-[12px]">Roasberry vs UpStackified</Link>
                                <Link to="/comparison/5" className="m-0 p-1 text-[16px] max-md:text-[12px]">Roasberry vs Kendall</Link>
                                <Link to="/comparison/6" className="m-0 p-1 text-[16px] max-md:text-[12px]">Roasberry vs Converge</Link>
                            </div>
                        )}
                    </div>

                    {/* Newsletter Subscription Section */}
                    <div className='w-full rounded-[12px] border border-[#2B2B2B] p-2 mt-8'>
                        <div className="w-full mt-8 p-1">
                            <p className="text-lg">Subscribe to <span className="text-[#4ED98B] italic">Roasberry’s Newsletter!</span></p>
                            <p className="text-sm mt-2">Get the latest insights on ad tracking, optimization tips, and industry trends delivered right to your inbox!</p>
                            <div className="flex flex-col mt-4">
                                <input type="email" placeholder="Enter your email" className="bg-transparent border border-[#2B2B2B]  placeholder:text-center rounded-[8px] px-4 py-2 text-sm text-white w-full mb-2" />
                                <button className="bg-[#CF0A54] text-white px-4 py-2 rounded-[8px]">Subscribe</button>
                            </div>
                        </div>
                    </div>
                    {/* Payment Methods and Social Icons */}
                    <div className="w-full mt-8 flex flex-col md:flex-row justify-between items-center gap-[3vh]">
                        <div className="flex flex-row w-full justify-between items-center">
                            <a href="https://apps.shopify.com/roasberry" target="_blank" rel="noopener noreferrer">
                                <img src={shopifyAppStore}
                                    className="object-fit w-full  max-md:w-3/4"
                                    alt="Shopify App Store" />
                            </a>
                            <Link to="https://www.instagram.com/roasberrycom?igsh=MXA1enZhOXZpd2xhdg==" >
                                <img src={BTInstaIcon} className="object-fill w-[42.46] h-[42.46] max-md:w-3/4" />
                            </Link>
                            <Link to="https://x.com/roasberrycom?s=21&t=QuKCweFoy8SdG0L9_k8zDA" >
                                <img src={BTXicon} className="object-fit w-[42.46] h-[42.46] max-md:w-3/4" />
                            </Link>
                            <Link to="https://www.linkedin.com/company/roasberry/" >
                                <img src={BTLinkedinIcon} className="object-fill w-[44px] h-[42.46] max-md:w-3/4" />
                            </Link>
                        </div>
                        <div className="flex flex-wrap">
                            <img src={newPaymentBT} className="h-[50px] w-full object-cover" draggable="false" />
                        </div>
                        <a
                            href="https://www.producthunt.com/posts/roasberry?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-roasberry"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=488960&theme=dark"
                                alt="Roasberry - Finally, an accurate, intuitive, and affordable pixel tracker. | Product Hunt"
                                style={{ width: '250px', height: '54px' }}
                                width="250"
                                height="54"
                            />
                        </a>
                    </div>

                    {/* Footer Bottom */}
                    <div className="w-full mt-8 text-center text-sm">
                        <p>© 2023 Roasberry Inc. All rights reserved.</p>
                    </div>
                </div>

                {isPopupOpen && <ScheduleDemoPopup onClose={togglePopup} />}
            </footer>
        </>
    );
};

export default BottomFooter;


