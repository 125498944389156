import React, { useState, useEffect } from "react";
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import './App.css';
import Navbar from './Navbar';
import NewHome from './NewHome.js';
import Resources from './Resources';
import Product from './Product';
import Newpricing from './Newpricing';
import { useTranslation } from 'react-i18next';
import i18n from './i18n.js';
import BlogPage from './BlogPage';
import blogNewsData from './blogNewsData.js';
import useGeoLocation from "react-ipgeolocation";
import ProductPageDashboard from "./ProductPageDashboard.js";
import ProductPageVisitor from "./ProductPageVisitor.js";
import Login from './Login';
import BlogCreator from './BlogCreator';
import BlogList from './BlogList';
import VSRoasberryComparison from './VSRoasberryComparison.js'; // Import the comparison component
import ScrollToTop from './ScrollToTop'; // Import the ScrollToTop component
import ResourcesBlog from "./ResourcesBlog.jsx";
import ResourcesTech from "./ResourcesTech.jsx";

function App() {
  const [topBarHeight, setTopBarHeight] = useState(0);
  const { t } = useTranslation();
  const [consentAccepted, setConsentAccepted] = useState(localStorage.getItem('cookiesConsent') === 'true');
  const [languageSet, setLanguageSet] = useState(false); // To avoid infinite loop
  const [isLoggedIn, setIsLoggedIn] = useState(false); // New state for login
  const [blogData, setBlogData] = useState(blogNewsData); // Initial blog data

  const handleAccept = () => {
    localStorage.setItem('cookiesConsent', 'true');
    setConsentAccepted(true);
  };

  const location = useGeoLocation();
  console.log("LOCATION" + location.country)
  useEffect(() => {
    if (!languageSet && location.loaded && !location.error) {
      const country = location.country;
      if (country === 'TR') {
        i18n.changeLanguage('tr');
      } else {
        i18n.changeLanguage('en');
      }
      setLanguageSet(true); // Mark language as set to avoid re-running
    }
  }, [location, languageSet]);

  useEffect(() => {
    document.body.style.overflow = consentAccepted ? 'visible' : 'hidden';
  }, [consentAccepted]);

  const handleLogin = (status) => {
    setIsLoggedIn(status);
  };

  const handleCreateBlog = (newBlog) => {
    setBlogData([...blogData, newBlog]);
  };

  return (
    <div className={`app`} style={{ paddingTop: consentAccepted ? topBarHeight : 0 }}>
      <div className={`container-app ${consentAccepted ? 'no-blur-background' : 'blur-background'}`}>
        <BrowserRouter>
          <Navbar setTopBarHeight={consentAccepted ? setTopBarHeight : () => { }} />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<NewHome />} />
            <Route path="/Home" element={<NewHome />} />
            <Route path="/comparison/:dataId" element={<VSRoasberryComparison />} />
            <Route path="/Product" element={<Product />} />
            <Route path="/Pricing" element={<Newpricing />} />
            <Route path="/BlogPage/:newsTitle" element={<BlogPage blogNewsData={blogData} />} />
            <Route path="/dashboard-and-insight-tools" element={<ProductPageDashboard />} />
            <Route path="/visitor-insights-log" element={<ProductPageVisitor />} />
            <Route path="/Blog" element={<ResourcesBlog />} />
            <Route path="/OurTechnology" element={<ResourcesTech />} />

            {/* New Routes for Blog Creation and Login */}
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route path="/create-blog" element={isLoggedIn ? <BlogCreator onCreate={handleCreateBlog} /> : <Login onLogin={handleLogin} />} />
            <Route path="/blog-list" element={isLoggedIn ? <BlogList blogData={blogData} /> : <Login onLogin={handleLogin} />} />
          </Routes>
        </BrowserRouter>
      </div>
      {!consentAccepted &&
        <div className="fixed bottom-[5vh] min-h-[40vh] justify-between  right-5 p-10 rounded-lg shadow-lg z-50 bg-black w-[90%] flex flex-col gap-2 sm:bottom-[20vh] sm:left-1/2 sm:transform sm:-translate-x-1/2 sm:w-[95%] md:w-[80%] lg:w-[60%]">
          <p className="text-white">{t('cookieSettings')}</p>
          <p className="text-white">{t('cookieDescription')}</p>
          <div className="flex flex-col w-full justify-around gap-3">
            <button
              onClick={handleAccept}
              className="bg-[#CF0A54] p-2 text-white rounded-lg w-[95%] mt-2 flex items-center justify-center border-none h-fit"
            >
              <p className="m-0">{t('acceptAll')}</p>
            </button>
            <button
              onClick={handleAccept}
              className="bg-black p-2 text-white rounded-lg w-[95%] mt-2 flex items-center justify-center border border-white h-fit"
            >
              <p className="m-0">{t('acceptEssential')}</p>
            </button>
          </div>
        </div>
      }
    </div>
  );
}

export default App;
