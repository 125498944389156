import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import productLogNavIcon from "./Images/productLogNavIcon.png";
import productDashboardNavIcon from "./Images/productDashboardNavIcon.png";
import loginIcon from './Images/loginIcon.png';
import CompareRoasberryConverge from "./vsroasberryImages/CompareRoasberryConverge.png";
import CompareRoasberryKendall from "./vsroasberryImages/CompareRoasberryKendall.png";
import CompareRoasberryNorthbeam from "./vsroasberryImages/CompareRoasberryNorthbeam.png";
import CompareRoasberryPolar from "./vsroasberryImages/CompareRoasberryPolar.png";
import CompareRoasberryTW from "./vsroasberryImages/CompareRoasberryTW.png";
import CompareRoasberryUpstack from "./vsroasberryImages/CompareRoasberryUpstack.png";
import menuOpenArrow from "./SVG/menuOpenArrow.svg";

import ResourcesBlogIcon from './SVG/ResourcesBlogIcon.svg';
import ResourcesTechIcon from './SVG/ResourcesTechIcon.svg';

const DesktopNavbar = ({
    handleLinkClick,
    handleNavbarLinkClick,
    handleScheduleDemoClick,
    handleProductClick,
    handleCompareClick,
    handleResourcesClick, // Add handleResourcesClick
    handleChildLinkClick,
    productLinkRef,
    compareLinkRef,
    resourcesLinkRef, // Add resourcesLinkRef
    showPopup,
    showComparePopup,
    showResourcesPopup, // Add showResourcesPopup
    popupPosition,
    handleClosePopup,
    handleCompareClosePopup,
    handleResourcesClosePopup // Add Resources close handler
}) => {
    const { t } = useTranslation();
    const productPopupRef = useRef(null);
    const comparePopupRef = useRef(null);
    const resourcesPopupRef = useRef(null); // Ref for Resources popup
    const [productLinkOpen, setProductLinkOpen] = useState(false);
    const [compareLinkOpen, setCompareLinkOpen] = useState(false);
    const [resourcesLinkOpen, setResourcesLinkOpen] = useState(false); // State for Resources arrow rotation

    const handleProductLinkOpen = () => {
        setProductLinkOpen(!productLinkOpen);
    };
    const handleCompareLinkOpen = () => {
        setCompareLinkOpen(!compareLinkOpen);
    };
    const handleResourcesLinkOpen = () => {
        setResourcesLinkOpen(!resourcesLinkOpen);
    };

    // Effect for handling clicks outside of popups
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (showPopup && productPopupRef.current && !productPopupRef.current.contains(event.target)) {
                handleClosePopup();
                handleProductLinkOpen();
            }
            if (showComparePopup && comparePopupRef.current && !comparePopupRef.current.contains(event.target)) {
                handleCompareClosePopup();
                handleCompareLinkOpen();
            }
            if (showResourcesPopup && resourcesPopupRef.current && !resourcesPopupRef.current.contains(event.target)) {
                handleResourcesClosePopup();
                handleResourcesLinkOpen();
            }
        };

        if (showPopup || showComparePopup || showResourcesPopup) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showPopup, showComparePopup, showResourcesPopup, handleClosePopup, handleCompareClosePopup, handleResourcesClosePopup]);


    const ProductPopup = ({ onClose }) => (
        <div ref={productPopupRef}
            className="absolute transition-transform transition-opacity duration-300 opacity-0 transform -translate-y-3 animate-fadeInDown" style={{ top: popupPosition.top, left: popupPosition.left }}>
            <div className="relative w-[584px] h-[134px]">
                <div className="absolute left-1/2 transform -translate-x-1/2 -top-2 w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-b-8 border-b-black"></div>
                <div className="bg-black rounded-2xl shadow w-full h-full relative">
                    <div className="absolute top-[17px] left-0 w-full h-[123px]">
                        <div className="flex flex-col justify-start items-start gap-4 px-[28px] mt-[17px] mb-[24px]">
                            <div className="text-[#ececec] text-xs font-semibold uppercase leading-[18px] tracking-wide">PRODUCT</div>
                            <div className="flex flex-row items-center justify-around gap-4 w-full">
                                <div className="w-64 h-12 bg-[#4d0420] rounded-lg justify-start items-center gap-2 inline-flex pl-2  hover:bg-transparent hover:border hover:border-[#4d0420] ">
                                    <img src={productDashboardNavIcon} className='w-[24px] h-[24px] relative' />
                                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
                                        <Link to="/dashboard-and-insight-tools" onClick={onClose} className="text-white text-base font-regular hover:no-underline">Dashboard and Insight Tools</Link>
                                    </div>
                                </div>
                                <div className="w-64 h-12 bg-[#4d0420] rounded-lg justify-start items-center gap-2 inline-flex pl-2 hover:bg-transparent hover:border hover:border-[#4d0420] ">
                                    <img src={productLogNavIcon} className='w-[24px] h-[24px] relative' />
                                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
                                        <Link to="/visitor-insights-log" onClick={onClose} className="text-white text-base font-regular hover:no-underline">Visitor Insights/Log</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const ComparePopup = ({ onClose }) => (
        <div ref={comparePopupRef}
            className="absolute transition-transform transition-opacity duration-300 opacity-0 transform -translate-y-3 animate-fadeInDown" style={{ top: popupPosition.top, left: popupPosition.left }}>
            <div className="relative w-[616px] h-[251px]">
                <div className="absolute left-1/2 transform -translate-x-1/2 -top-2 w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-b-8 border-b-black"></div>
                <div className="bg-black rounded-2xl shadow w-full h-full relative">
                    <div className="absolute top-[11px] left-0 w-full h-[210px]">
                        <div className="absolute flex flex-col w-full justify-start items-start gap-4 px-7 top-[17px]">
                            <div className="text-[#ececec] text-xs font-semibold uppercase leading-[18px] tracking-wide">COMPARE US</div>
                            <div className="grid grid-cols-2 gap-y-4 gap-x-4 w-full">
                                <div className="w-[272px] h-12 bg-[#4d0420] rounded-lg justify-start items-center gap-2 inline-flex pl-2  hover:bg-transparent hover:border hover:border-[#4d0420] ">
                                    <img src={CompareRoasberryTW} className='w-[40px] h-[24px]' />
                                    <Link to="/comparison/1" onClick={onClose} className="text-white text-base font-semibold  hover:no-underline">
                                        Roasberry vs Triple Whale
                                    </Link>
                                </div>
                                <div className="w-[272px] h-12 px-2 py-3 bg-[#4d0420] rounded-lg justify-start items-center gap-1 inline-flex hover:bg-transparent hover:border hover:border-[#4d0420] ">
                                    <img src={CompareRoasberryPolar} className='w-[40px] h-[24px]' />
                                    <Link to="/comparison/3" onClick={onClose} className="text-white text-base font-semibold  hover:no-underline">
                                        Roasberry vs Polar Analytics
                                    </Link>
                                </div>
                                <div className="w-[272px] h-12 px-2 py-3 bg-[#4d0420] rounded-lg justify-start items-center gap-1 inline-flex hover:bg-transparent hover:border hover:border-[#4d0420] ">
                                    <img src={CompareRoasberryNorthbeam} className='w-[40px] h-[24px]' />
                                    <Link to="/comparison/2" onClick={onClose} className="text-white text-base font-semibold  hover:no-underline">
                                        Roasberry vs Northbeam
                                    </Link>
                                </div>
                                <div className="w-[272px] h-12 px-2 py-3 bg-[#4d0420] rounded-lg justify-start items-center gap-1 inline-flex hover:bg-transparent hover:border hover:border-[#4d0420] ">
                                    <img src={CompareRoasberryUpstack} className='w-[40px] h-[24px]' />
                                    <Link to="/comparison/4" onClick={onClose} className="text-white text-base font-semibold  hover:no-underline">
                                        Roasberry vs Upstackified
                                    </Link>
                                </div>
                                <div className="w-[272px] h-12 px-2 py-3 bg-[#4d0420] rounded-lg justify-start items-center gap-1 inline-flex hover:bg-transparent hover:border hover:border-[#4d0420] ">
                                    <img src={CompareRoasberryKendall} className='w-[40px] h-[24px]' />
                                    <Link to="/comparison/5" onClick={onClose} className="text-white text-base font-semibold  hover:no-underline">
                                        Roasberry vs Kendall
                                    </Link>
                                </div>
                                <div className="w-[272px] h-12 px-2 py-3 bg-[#4d0420] rounded-lg justify-start items-center gap-1 inline-flex hover:bg-transparent hover:border hover:border-[#4d0420] ">
                                    <img src={CompareRoasberryConverge} className='w-[40px] h-[24px]' />
                                    <Link to="/comparison/6" onClick={onClose} className="text-white text-base font-semibold  hover:no-underline">
                                        Roasberry vs Converge
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const ResourcesPopup = ({ onClose }) => (
        <div ref={resourcesPopupRef}
            className="absolute transition-transform transition-opacity duration-300 opacity-0 transform -translate-y-3 animate-fadeInDown" style={{ top: popupPosition.top, left: popupPosition.left }}>
            <div className="relative w-[584px] h-[134px]">
                <div className="absolute left-1/2 transform -translate-x-1/2 -top-2 w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-b-8 border-b-black"></div>
                <div className="bg-black rounded-2xl shadow w-full h-full relative">
                    <div className="absolute top-[17px] left-0 w-full h-[123px]">
                        <div className="flex flex-col justify-start items-start gap-4 px-[28px] mt-[17px] mb-[24px]">
                            <div className="text-[#ececec] text-xs font-semibold uppercase leading-[18px] tracking-wide">Resources</div>
                            <div className="flex flex-row items-center justify-around gap-4 w-full">
                                <div className="w-64 h-12 bg-[#4d0420] rounded-lg justify-start items-center gap-2 inline-flex pl-2  hover:bg-transparent hover:border hover:border-[#4d0420] ">
                                    <img src={ResourcesBlogIcon} className='w-[24px] h-[24px] relative' />
                                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
                                        <Link to="/Blog" onClick={onClose} className="text-white text-base font-regular hover:no-underline">Blog (Roasberry’s News)</Link>
                                    </div>
                                </div>
                                <div className="w-64 h-12 bg-[#4d0420] rounded-lg justify-start items-center gap-2 inline-flex pl-2 hover:bg-transparent hover:border hover:border-[#4d0420] ">
                                    <img src={ResourcesTechIcon} className='w-[24px] h-[24px] relative' />
                                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
                                        <Link to="/OurTechnology" onClick={onClose} className="text-white text-base font-regular hover:no-underline">About Us & Our Technology</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    return (
        <>
            <div className="hidden md:flex justify-between items-center py-4 h-full bg-opacity-10 px-6 w-full">
                <div className="font-bold text-white min-w-[40px] max-w-[120px]"></div>
                <div className="flex-grow flex justify-center">
                    <ul className="flex flex-row gap-8 list-none p-0">
                        <li>
                            <NavLink exact to="/Home" className="no-underline text-[#ECECEC] opacity-60 text-[16px] hover:text-white hover:opacity-100 hover:no-underline"
                                activeClassName="text-white opacity-100" onClick={() => { handleLinkClick('Home'); handleNavbarLinkClick(); }}>
                                {t('home')}
                            </NavLink>
                        </li>
                        <li ref={productLinkRef}>
                            <NavLink exact to="#"
                                className=" flex flex-row items-center no-underline text-[#ECECEC] opacity-60 text-[16px]  hover:text-white hover:opacity-100 hover:no-underline"
                                activeClassName="text-white opacity-100"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleLinkClick('Product');
                                    handleProductClick();
                                    handleProductLinkOpen()
                                }}>
                                {t('product')}
                                <img
                                    src={menuOpenArrow}
                                    className={`w-[20px] h-[20px] ml-1 transition-transform duration-300 ${productLinkOpen ? 'rotate-180' : ''}`}
                                />
                            </NavLink>
                        </li>
                        <li ref={compareLinkRef}>
                            <NavLink
                                exact
                                to="#"
                                className=" flex flex-row items-center no-underline text-[#ECECEC] opacity-60 text-[16px]  hover:text-white hover:opacity-100 hover:no-underline"
                                activeClassName="text-white opacity-100"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleLinkClick('Compare Us');
                                    handleCompareClick();  // Trigger the compare popup
                                    handleCompareLinkOpen()
                                }}
                            >
                                {t('compareUs')}
                                <img
                                    src={menuOpenArrow}
                                    className={`w-[20px] h-[20px] ml-1 transition-transform duration-300 ${compareLinkOpen ? 'rotate-180' : ''}`}
                                />

                            </NavLink>
                        </li>

                        <li>
                            <NavLink exact to="/Pricing" className="no-underline text-[#ECECEC] opacity-60 text-[16px]  hover:text-white hover:opacity-100 hover:no-underline"
                                activeClassName="text-white opacity-100" onClick={() => { handleLinkClick('Pricing'); handleNavbarLinkClick(); }}>
                                {t('pricing')}
                            </NavLink>
                        </li>
                        <li ref={resourcesLinkRef}>
                            <NavLink exact to="/Resources"
                                className=" flex flex-row items-center no-underline text-[#ECECEC] opacity-60 text-[16px]  hover:text-white hover:opacity-100 hover:no-underline"
                                activeClassName="text-white opacity-100"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleLinkClick('Resources');
                                    handleResourcesClick();  // Trigger the resources popup
                                    handleResourcesLinkOpen();
                                }}>
                                {t('resources')}
                                <img
                                    src={menuOpenArrow}
                                    className={`w-[20px] h-[20px] ml-1 transition-transform duration-300 ${resourcesLinkOpen ? 'rotate-180' : ''}`}
                                />

                            </NavLink>
                        </li>
                    </ul>
                </div>
                <ul className="flex flex-row gap-8 list-none p-0">
                    <li>
                        <NavLink exact to="https://app.roasberry.com/#/Login" className="text-white text-[16px] no-underline flex items-center gap-2  hover:text-white hover:opacity-100 hover:no-underline">
                            <img src={loginIcon} className='w-[22px]' alt="Login Icon" />
                            {t('login')}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="" className="text-white text-[16px] no-underline bg-[#CF0A5450] px-6 py-3 border-none rounded-[32px] shadow  hover:text-white hover:opacity-100 hover:no-underline" onClick={handleScheduleDemoClick}>
                            {t('bookDemo')}
                        </NavLink>
                    </li>
                </ul>
            </div>
            {showPopup && <ProductPopup onClose={handleClosePopup} />}
            {showComparePopup && <ComparePopup onClose={handleCompareClosePopup} />}
            {showResourcesPopup && <ResourcesPopup onClose={handleResourcesClosePopup} />}
        </>
    );
};

export default DesktopNavbar;
