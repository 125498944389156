import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { CSSTransition } from 'react-transition-group';
import loginIcon from './Images/loginIcon.png';
import productLogNavIcon from "./Images/productLogNavIcon.png";
import productDashboardNavIcon from "./Images/productDashboardNavIcon.png";
import { useTranslation } from 'react-i18next';
import logoTransparent from './Images/logoTransparent.png';
import './Navbar.css';
import arrowRight from "./Images/arrow.right.png";
import arrowDown from "./Images/arrow.down.png";


import CompareRoasberryConverge from "./vsroasberryImages/CompareRoasberryConverge.png";
import CompareRoasberryKendall from "./vsroasberryImages/CompareRoasberryKendall.png";
import CompareRoasberryNorthbeam from "./vsroasberryImages/CompareRoasberryNorthbeam.png";
import CompareRoasberryPolar from "./vsroasberryImages/CompareRoasberryPolar.png";
import CompareRoasberryTW from "./vsroasberryImages/CompareRoasberryTW.png";
import CompareRoasberryUpstack from "./vsroasberryImages/CompareRoasberryUpstack.png";

import ResourcesBlogIcon from './SVG/ResourcesBlogIcon.svg';
import ResourcesTechIcon from './SVG/ResourcesTechIcon.svg';

const MobileNavbar = ({ handleLinkClick, handleNavbarLinkClick, handleScheduleDemoClick, productLinkRef, productMenuOpen, handleProductClick, handleChildLinkClick, resourceslinkRef }) => {
    const { t } = useTranslation();
    const [showNavbar, setShowNavbar] = useState(false);
    const [compareMenuOpen, setCompareMenuOpen] = useState(false); // State for the Compare Us menu
    const compareLinkRef = useRef(null); // Ref for Compare Us link


    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar);
    };

    const handleCloseNavbar = () => {
        setShowNavbar(false);
    };

    const handleCompareClick = () => {
        setCompareMenuOpen(!compareMenuOpen);
    };
    const [resourcesMenuOpen, setResourcesMenuOpen] = useState(false); // New state for Resources menu

    const handleResourcesClick = () => {
        setResourcesMenuOpen(!resourcesMenuOpen); // Correctly toggle the resources menu
    };


    useEffect(() => {
        if (showNavbar) {
            // Disable scrolling on the background
            document.body.style.overflow = "hidden";
        } else {
            // Enable scrolling on the background when modal is closed
            document.body.style.overflow = "";
        }

        // Cleanup function to ensure scrolling is re-enabled when the component unmounts
        return () => {
            document.body.style.overflow = "";
        };
    }, [showNavbar]);

    return (
        <>
            <div className="block md:hidden cursor-pointer" onClick={handleShowNavbar}>
                <FaBars size={24} style={{ color: "white" }} />
            </div>
            <CSSTransition
                in={showNavbar}
                timeout={300}
                classNames="navbar"
                unmountOnExit
            >
                <div className="md:hidden flex flex-col bg-black w-full h-screen fixed top-0 left-0 z-50">
                    <div className="flex justify-between items-center p-4">
                        <img src={logoTransparent} alt="Logo" className="w-[160px]" />
                        <FaTimes size={24} style={{ color: "white" }} onClick={handleCloseNavbar} />
                    </div>
                    <div className='w-full justify-between h-fit overflow-y-auto'>
                        <ul className="flex flex-col list-none p-4 h-fit gap-4 justify-between">
                            <li className='min-h-[40px]'>
                                <NavLink
                                    exact
                                    to="/Home"
                                    className="no-underline text-[#ECECEC] opacity-60 text-[16px] whitespace-nowrap"
                                    activeClassName="text-white opacity-100"
                                    onClick={() => {
                                        handleLinkClick('Home');
                                        handleNavbarLinkClick();
                                        handleCloseNavbar();
                                    }}>
                                    {t('home')}
                                </NavLink>
                            </li>
                            <li ref={productLinkRef} className='min-h-[40px]'>
                                <NavLink
                                    exact
                                    to="#"
                                    className="no-underline text-[#ECECEC] opacity-60 text-[16px] whitespace-nowrap flex flex-row items-center"
                                    activeClassName="text-white opacity-100"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleLinkClick('Product');
                                        handleProductClick();
                                    }}>
                                    {t('product')} <img className="ml-auto right-5 w-[17px] h-[17px]" src={productMenuOpen ? arrowRight : arrowDown} />
                                </NavLink>
                                <ul className={`mt-2 flex flex-col gap-4 transition-all duration-300 ${productMenuOpen ? 'max-h-[200px] opacity-100' : 'max-h-0 opacity-0'}`}>
                                    <li className="h-[40px] p-3 bg-[#1A1A1A] rounded-[8px] flex justify-start items-center gap-2">
                                        <a href="#" className="flex justify-start items-center gap-2 text-white text-sm font-normal" onClick={(e) => { e.preventDefault(); handleChildLinkClick('/dashboard-and-insight-tools'); handleCloseNavbar(); }}>
                                            <img src={productDashboardNavIcon} className='w-[24px] h-[24px]' alt="Dashboard and Insight Tools" />
                                            <span>Dashboard and Insight Tools</span>
                                        </a>
                                    </li>
                                    <li className="h-[40px] p-3 bg-[#1A1A1A] rounded-[8px] flex justify-start items-center gap-2 shadow-2xl">
                                        <a href="#" className="flex justify-start items-center gap-2 text-white text-sm font-normal" onClick={(e) => { e.preventDefault(); handleChildLinkClick('/visitor-insights-log'); handleCloseNavbar(); }}>
                                            <img src={productLogNavIcon} className='w-[24px] h-[24px]' alt="Visitor Insights/Log" />
                                            <span>Visitor Insights/Log</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>


                            <li className='min-h-[40px]'>
                                <NavLink
                                    exact
                                    to="/Pricing"
                                    className="no-underline text-[#ECECEC] opacity-60 text-[16px] whitespace-nowrap"
                                    activeClassName="text-white opacity-100"
                                    onClick={() => {
                                        handleLinkClick('Pricing');
                                        handleNavbarLinkClick();
                                        handleCloseNavbar();
                                    }}>
                                    {t('pricing')}
                                </NavLink>
                            </li>
                            <li ref={resourceslinkRef} className='min-h-[40px]'>
                                <NavLink
                                    exact
                                    to="#"
                                    className="no-underline text-[#ECECEC] opacity-60 text-[16px] whitespace-nowrap flex flex-row items-center"
                                    activeClassName="text-white opacity-100"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleLinkClick('Resources');
                                        handleResourcesClick();
                                    }}>
                                    {t('Resources')} <img className="ml-auto right-5 w-[17px] h-[17px]" src={resourcesMenuOpen ? arrowRight : arrowDown} />
                                </NavLink>
                                <ul className={`mt-2 flex flex-col gap-4 transition-all duration-300 ${resourcesMenuOpen ? 'max-h-[200px] opacity-100' : 'max-h-0 opacity-0'}`}>
                                    <li className="h-[40px] p-3 bg-[#1A1A1A] rounded-[8px] flex justify-start items-center gap-2">
                                        <a href="#" className="flex justify-start items-center gap-2 text-white text-sm font-normal" onClick={(e) => { e.preventDefault(); handleChildLinkClick('/Blog'); handleCloseNavbar(); }}>
                                            <img src={ResourcesBlogIcon} className='w-[24px] h-[24px]' alt="Dashboard and Insight Tools" />
                                            <span>Blog (Roasberry’s News)</span>
                                        </a>
                                    </li>
                                    <li className="h-[40px] p-3 bg-[#1A1A1A] rounded-[8px] flex justify-start items-center gap-2 shadow-2xl">
                                        <a href="#" className="flex justify-start items-center gap-2 text-white text-sm font-normal" onClick={(e) => { e.preventDefault(); handleChildLinkClick('/OurTechnology'); handleCloseNavbar(); }}>
                                            <img src={ResourcesTechIcon} className='w-[24px] h-[24px]' alt="About Us & Our Technology" />
                                            <span>About Us & Our Technology</span>
                                        </a>
                                    </li>
                                </ul>

                            </li>

                            {/* Compare Us menu */}
                            <li ref={compareLinkRef} className='min-h-[40px]'>
                                <NavLink
                                    exact
                                    to="#"
                                    className="no-underline text-[#ECECEC] opacity-60 text-[16px] whitespace-nowrap flex flex-row items-center"
                                    activeClassName="text-white opacity-100"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleLinkClick('Compare Us');
                                        handleCompareClick();
                                    }}>
                                    {t('compareUs')} <img className="ml-auto right-5 w-[17px] h-[17px]" src={compareMenuOpen ? arrowRight : arrowDown} />
                                </NavLink>
                                <ul className={`mt-2 flex flex-col gap-4 transition-all duration-300 ${compareMenuOpen ? 'max-h-[200px] opacity-100' : 'max-h-0 opacity-0'}`}>
                                    <li className="h-[40px] p-3 bg-[#1A1A1A] rounded-[8px] flex justify-start items-center gap-2">
                                        <a href="#" className="flex justify-start items-center gap-2 text-white text-sm font-normal" onClick={(e) => { e.preventDefault(); handleChildLinkClick('/comparison/1'); handleCloseNavbar(); }}>
                                            <img src={CompareRoasberryTW} className='w-[40px] h-[24px]' alt="Roasberry vs Triple Whale" />
                                            <span>Roasberry vs Triple Whale</span>
                                        </a>
                                    </li>
                                    <li className="h-[40px] p-3 bg-[#1A1A1A] rounded-[8px] flex justify-start items-center gap-2">
                                        <a href="#" className="flex justify-start items-center gap-2 text-white text-sm font-normal" onClick={(e) => { e.preventDefault(); handleChildLinkClick('/comparison/3'); handleCloseNavbar(); }}>
                                            <img src={CompareRoasberryPolar} className='w-[40px] h-[24px]' alt="Roasberry vs Polar Analytics" />
                                            <span>Roasberry vs Polar Analytics</span>
                                        </a>
                                    </li>
                                    <li className="h-[40px] p-3 bg-[#1A1A1A] rounded-[8px] flex justify-start items-center gap-2">
                                        <a href="#" className="flex justify-start items-center gap-2 text-white text-sm font-normal" onClick={(e) => { e.preventDefault(); handleChildLinkClick('/comparison/2'); handleCloseNavbar(); }}>
                                            <img src={CompareRoasberryNorthbeam} className='w-[40px] h-[24px]' alt="Roasberry vs Northbeam" />
                                            <span>Roasberry vs Northbeam</span>
                                        </a>
                                    </li>
                                    <li className="h-[40px] p-3 bg-[#1A1A1A] rounded-[8px] flex justify-start items-center gap-2">
                                        <a href="#" className="flex justify-start items-center gap-2 text-white text-sm font-normal" onClick={(e) => { e.preventDefault(); handleChildLinkClick('/comparison/4'); handleCloseNavbar(); }}>
                                            <img src={CompareRoasberryUpstack} className='w-[40px] h-[24px]' alt="Roasberry vs Upstackified" />
                                            <span>Roasberry vs Upstackified</span>
                                        </a>
                                    </li>
                                    <li className="h-[40px] p-3 bg-[#1A1A1A] rounded-[8px] flex justify-start items-center gap-2">
                                        <a href="#" className="flex justify-start items-center gap-2 text-white text-sm font-normal" onClick={(e) => { e.preventDefault(); handleChildLinkClick('/comparison/5'); handleCloseNavbar(); }}>
                                            <img src={CompareRoasberryKendall} className='w-[40px] h-[24px]' alt="Roasberry vs Upstackified" />
                                            <span>Roasberry vs Kendall</span>
                                        </a>
                                    </li>
                                    <li className="h-[40px] p-3 bg-[#1A1A1A] rounded-[8px] flex justify-start items-center gap-2">
                                        <a href="#" className="flex justify-start items-center gap-2 text-white text-sm font-normal" onClick={(e) => { e.preventDefault(); handleChildLinkClick('/comparison/6'); handleCloseNavbar(); }}>
                                            <img src={CompareRoasberryConverge} className='w-[40px] h-[24px]' alt="Roasberry vs Upstackified" />
                                            <span>Roasberry vs Converge</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <ul className="flex flex-col list-none p-4 mt-auto pb-[15vh] ">
                        <li>
                            <NavLink
                                exact
                                to="https://app.roasberry.com/#/Login"
                                className="text-white text-[16px] no-underline flex items-center gap-2 bg-[#CF0A54] px-6 py-3 rounded-[32px] shadow mb-4 justify-center">
                                <img src={loginIcon} className='w-[22px]' alt="Login Icon" />
                                {t('login')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                exact
                                to=""
                                className="text-white text-[16px] no-underline bg-black px-6 py-3 border border-white/20 rounded-[32px] shadow justify-center flex"
                                onClick={handleScheduleDemoClick}>
                                {t('bookDemo')}
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </CSSTransition>
        </>
    );
};

export default MobileNavbar;
