import React from "react";
import { useEffect, useState } from "react";
import "./Resources.css";
import BottomFooter from "./bottomfooter";
import ScheduleDemoPopup from "./ScheduleDemoPopup";
import infoButton from "./Images//InfoButton.png";
import blogIconAndText from "./Images//BlogIconAndText.png";
import { Link, useNavigate } from "react-router-dom";
import aboutUsHeader from "./Images/Buyuk aboutUsHeader.png";
import resouresBerryIcon from "./Images/resouresBerryIcon.png";
import resourcesTechIcon from "./Images/resourcesTechIcon.png";
import blogNewsData from "./blogNewsData.js";
import { useLocation } from 'react-router-dom';

import MarketingIcon from "./Images/MarketingIcon.png";
import AIIcon from "./Images/AIIcon.png";
import AlgoIcon from "./Images/AlgoIcon.png";
import DataIcon from "./Images/DataIcon.png";

import mailIcon from "./Images/mailIcon.png";
import phoneIcon from "./Images/phoneIcon.png";
import locationIcon from "./Images/locationIcon.png";
import ResourcesForm from "./ResourcesForm.jsx";
import blogheader from "./blogheader.png";
import aboutusImage from "./aboutusImage.png";
import { useTranslation } from "react-i18next";
function ResourcesBlog(props) {
    const { t } = useTranslation();
    const [selectedButton, setSelectedButton] = useState("Marketing Metrics");
    const buttonClickHandler = (buttonName) => {
        setSelectedButton(buttonName === selectedButton ? null : buttonName);
    };
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };
    const navigate = useNavigate();
    const [showNavbar, setShowNavbar] = React.useState(false);
    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar);
    };
    const handleBlogLinkClick = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerColumn = 3;
    const indexOfLastItem = currentPage * itemsPerColumn;
    const indexOfFirstItem = indexOfLastItem - itemsPerColumn;
    const currentItems = blogNewsData.slice(indexOfFirstItem, indexOfLastItem);
    const indexOfLastItemTop = blogNewsData.length - 1;
    const topItems = blogNewsData.slice(
        indexOfLastItemTop - 1,
        indexOfLastItemTop + 1
    );
    const totalPages = Math.ceil(blogNewsData.length / itemsPerColumn);
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
    const algorithmsData = [
        {
            algorithmsId: 1,
            algorithmsIcon: MarketingIcon,
            algorithmsTitle: t("Marketing Metrics"),
            algorithmsDescription: t(
                "Exploring beyond micro marketing measurements and considering the holistic view of various marketing efforts is crucial for brands in eCommerce. It is not sufficient to rely solely on channel-specific results or campaign ROAS when making major marketing decisions. To make the best decisions for your business, it is essential to examine case studies, delve into actionable strategies, and understand the overall customer journey. By doing so, brands can gain valuable insights into how different marketing initiatives contribute to their return on investment."
            ),
        },
        {
            algorithmsId: 2,
            algorithmsIcon: DataIcon,
            algorithmsTitle: t("Data Analytics"),
            algorithmsDescription: t(
                "Through advanced data analytics, the dashboard leverages AI algorithms to analyze vast amounts of customer data, including purchase history, browsing behavior, demographics, and more. These algorithms identify patterns, trends, and correlations that may go unnoticed to human analysts, enabling businesses to make data-driven decisions."
            ),
        },
        {
            algorithmsId: 3,
            algorithmsIcon: AIIcon,
            algorithmsTitle: t("AI & ML Powered Dashboards"),
            algorithmsDescription: t(
                "One of the key features of the AI and ML-powered dashboard is its ability to segment customers based on their preferences, behaviors, and purchase patterns. By segmenting customers effectively, businesses can tailor their marketing messages, promotions, and product recommendations to specific groups, leading to higher conversion rates and personalized experiences."
            ),
        },
        {
            algorithmsId: 4,
            algorithmsIcon: AlgoIcon,
            algorithmsTitle: t("Algorithms"),
            algorithmsDescription: t(
                "The AI-powered dashboard utilizes K-means clustering to automatically identify meaningful customer segments, which can then be visualized and analyzed through intuitive dashboards and visualizations. These segments represent clusters of customers who exhibit similar characteristics or behaviors, allowing businesses to tailor their marketing strategies and offerings accordingly."
            ),
        },
    ];
    const reactRouterLocation = useLocation();  // Use a different variable name
    useEffect(() => {
        const queryParams = new URLSearchParams(reactRouterLocation.search);
        const scrollTo = queryParams.get('scrollTo');

        if (scrollTo === 'aboutUs') {
            const aboutUsElement = document.getElementById('about-us-section');
            if (aboutUsElement) {
                // Scroll smoothly to the section
                aboutUsElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [reactRouterLocation]);
    const FeaturesSection = ({ title, subtitle, description, highlightText }) => {
        return (
            <div className="w-11/12 max-md:w-11/12 h-full max-md:h-full flex-col justify-start items-center gap-2 inline-flex my-4">
                {/* "Our Features" Button */}
                <div className="px-4 bg-[#e30b5c] rounded-[51.43px] justify-center items-center inline-flex">
                    <div className="text-white text-lg font-medium font-['Raleway'] leading-[41.66px] max-md:text-[18px]">
                        {title}
                    </div>
                </div>

                {/* "Services We Offer" Heading */}
                <div className="text-center text-white text-[49px] max-md:text-4xl font-normal font-['Raleway'] leading-[58.80px] max-md:leading-[43.20px]">
                    {subtitle}
                </div>

                {/* Description Text */}
                <div className="text-center">
                    <span className="text-[#ececec] max-md:text-[#f1f1f1] text-xl max-md:text-[13px] font-normal font-['Raleway'] leading-7 max-md:leading-[20.80px]">
                        {description}
                    </span>
                    <span className="text-[#56ee99] text-2xl m max-md:text-[13px] font-normal font-['Times New Roman'] italic leading-[33.60px] max-md:leading-relaxed">
                        {" "} {highlightText}
                    </span>
                </div>
            </div>
        );
    };
    return (
        <>

            <div className="ResourcesPage-BlogContainer">
                <FeaturesSection
                    title="Blog"
                    subtitle="Roasberry’s News"
                    description="Read about industry trends and stories surrounding digital marketing for e-commerce businesses and"
                    highlightText="understand how Roasberry’s technological developments are helping enterprises position themselves for growth."
                />
                <div className="blog-CardView">
                    {topItems.map((newsItem) => (
                        <div className="blog-Card-column" key={newsItem.newsId}>
                            <Link to={`/BlogPage/${newsItem.newsTitle.replace(/\s+/g, '-').toLowerCase()}`} key={newsItem.newsId}>
                                <div className="blog-Card">
                                    <img
                                        src={newsItem.newsImage}
                                        alt={t(newsItem.newsTitle)}
                                        className="newsImage"
                                        draggable="false"
                                    />
                                    <p className="max-md:text-[13px]">{t(newsItem.newsTitle)}</p>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-evenly",
                                            alignItems: "initial",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-evenly",
                                                marginRight: "auto",
                                            }}
                                        >
                                            <img
                                                src={resouresBerryIcon}
                                                className="resourcesBerryIcon"
                                                draggable="false"
                                                style={{
                                                    height: "16px",
                                                    width: "auto",
                                                    marginRight: "2%",
                                                }}
                                            />
                                            <p className="max-md:text-[13px]">
                                                {t(newsItem.NewsWriter)}
                                            </p>
                                        </div>
                                        <p className="max-md:text-[13px]">
                                            {t(newsItem.NewsPublishedDate)}
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
                <div className="blog-CardView">
                    {currentItems.map((newsItem) => (
                        <div
                            className="blog-Card-column max-md:text-[13px]"
                            key={newsItem.newsId}
                        >
                            <Link to={`/BlogPage/${newsItem.newsTitle.replace(/\s+/g, '-').toLowerCase()}`} key={newsItem.newsId}>
                                <div className="blog-Card">
                                    <img
                                        src={newsItem.newsImage}
                                        alt={t(newsItem.newsTitle)}
                                        className="newsImage"
                                        draggable="false"
                                    />
                                    <p className="max-md:text-[13px]">{t(newsItem.newsTitle)}</p>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-evenly",
                                            alignItems: "initial",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-evenly",
                                                marginRight: "auto",
                                            }}
                                        >
                                            <img
                                                src={resouresBerryIcon}
                                                className="resourcesBerryIcon"
                                                draggable="false"
                                                style={{
                                                    height: "16px",
                                                    width: "auto",
                                                    marginRight: "2%",
                                                }}
                                            />
                                            <p className="max-md:text-[13px]">
                                                {t(newsItem.NewsWriter)}
                                            </p>
                                        </div>
                                        <p className="max-md:text-[13px]">
                                            {t(newsItem.NewsPublishedDate)}
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <BottomFooter />
        </>

    );
}
export default ResourcesBlog;