import React from "react";
import { useEffect, useState } from "react";
import "./Resources.css";
import BottomFooter from "./bottomfooter";
import ScheduleDemoPopup from "./ScheduleDemoPopup";
import { Link, useNavigate } from "react-router-dom";
import blogNewsData from "./blogNewsData.js";
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import OurTechHeroSection from "./SVG/OurTechHeroSection.svg"
import ResourcesAlgorithm from "./SVG/ResourcesAlgorithm.svg"
import ResourcesDashboard from "./SVG/ResourcesDashboard.svg";
import ResourcesData from "./SVG/ResourcesData.svg";
import ResourcesMarketing from "./SVG/ResourcesMarketingMetrics.svg";
import { motion } from "framer-motion"
function ResourcesTech(props) {
    const { t } = useTranslation();
    const [selectedButton, setSelectedButton] = useState("Marketing Metrics");

    const buttonClickHandler = (buttonName) => {
        setSelectedButton(buttonName === selectedButton ? null : buttonName);
    };
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerColumn = 3;
    const indexOfLastItem = currentPage * itemsPerColumn;
    const indexOfFirstItem = indexOfLastItem - itemsPerColumn;
    const currentItems = blogNewsData.slice(indexOfFirstItem, indexOfLastItem);
    const indexOfLastItemTop = blogNewsData.length - 1;
    const topItems = blogNewsData.slice(
        indexOfLastItemTop - 1,
        indexOfLastItemTop + 1
    );

    const totalPages = Math.ceil(blogNewsData.length / itemsPerColumn);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    function copyToClipboard() {
        const emailText = "info@roasberry.com";
        const textArea = document.createElement("textarea");
        textArea.value = emailText;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        const copyIcon = document.getElementById("copyIcon");
        copyIcon.innerText = "Copied!";
        setTimeout(() => {
            copyIcon.innerText = " ⧉";
        }, 2000);
    }

    const algorithmsData = [
        {
            algorithmsId: 1,
            algorithmsIcon: ResourcesMarketing,
            algorithmsTitle: t("Marketing Metrics"),
            algorithmsDescription: t(
                "Exploring beyond micro marketing measurements and considering the holistic view of various marketing efforts is crucial for brands in eCommerce. It is not sufficient to rely solely on channel-specific results or campaign ROAS when making major marketing decisions. To make the best decisions for your business, it is essential to examine case studies, delve into actionable strategies, and understand the overall customer journey. By doing so, brands can gain valuable insights into how different marketing initiatives contribute to their return on investment."
            ),
        },
        {
            algorithmsId: 2,
            algorithmsIcon: ResourcesData,
            algorithmsTitle: t("Data Analytics"),
            algorithmsDescription: t(
                "Through advanced data analytics, the dashboard leverages AI algorithms to analyze vast amounts of customer data, including purchase history, browsing behavior, demographics, and more. These algorithms identify patterns, trends, and correlations that may go unnoticed to human analysts, enabling businesses to make data-driven decisions."
            ),
        },
        {
            algorithmsId: 3,
            algorithmsIcon: ResourcesDashboard,
            algorithmsTitle: t("AI & ML Powered Dashboards"),
            algorithmsDescription: t(
                "One of the key features of the AI and ML-powered dashboard is its ability to segment customers based on their preferences, behaviors, and purchase patterns. By segmenting customers effectively, businesses can tailor their marketing messages, promotions, and product recommendations to specific groups, leading to higher conversion rates and personalized experiences."
            ),
        },
        {
            algorithmsId: 4,
            algorithmsIcon: ResourcesAlgorithm,
            algorithmsTitle: t("Algorithms"),
            algorithmsDescription: t(
                "The AI-powered dashboard utilizes K-means clustering to automatically identify meaningful customer segments, which can then be visualized and analyzed through intuitive dashboards and visualizations. These segments represent clusters of customers who exhibit similar characteristics or behaviors, allowing businesses to tailor their marketing strategies and offerings accordingly."
            ),
        },
    ];

    const [selectedAlgorithm, setSelectedAlgorithm] = useState(algorithmsData[0]);
    const handleAlgorithmButtonClick = (algorithm) => {
        setSelectedAlgorithm(algorithm);
    };
    useEffect(() => {
        setSelectedAlgorithm(algorithmsData[0]);
    }, []);


    const reactRouterLocation = useLocation();  // Use a different variable name
    useEffect(() => {
        const queryParams = new URLSearchParams(reactRouterLocation.search);
        const scrollTo = queryParams.get('scrollTo');

        if (scrollTo === 'aboutUs') {
            const aboutUsElement = document.getElementById('about-us-section');
            if (aboutUsElement) {
                // Scroll smoothly to the section
                aboutUsElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [reactRouterLocation]);
    const FeaturesSection = ({ title, subtitle, description, highlightText }) => {
        return (
            <div className="w-11/12 max-md:w-11/12 h-full max-md:h-full flex-col justify-start items-center gap-2 inline-flex my-4">
                {/* "Our Features" Button */}
                <div className="px-4 bg-[#e30b5c] rounded-[51.43px] justify-center items-center inline-flex">
                    <div className="text-white text-lg font-medium font-['Raleway'] leading-[41.66px] max-md:text-[18px]">
                        {title}
                    </div>
                </div>

                {/* "Services We Offer" Heading */}
                <div className="text-center text-white text-[49px] max-md:text-4xl font-normal font-['Raleway'] leading-[58.80px] max-md:leading-[43.20px]">
                    {subtitle}
                </div>

                {/* Description Text */}
                <div className="text-center">
                    <span className="text-[#ececec] max-md:text-[#f1f1f1] text-xl max-md:text-[13px] font-normal font-['Raleway'] leading-7 max-md:leading-[20.80px]">
                        {description}
                    </span>
                    <span className="text-[#56ee99] text-2xl m max-md:text-[13px] font-normal font-['Times New Roman'] italic leading-[33.60px] max-md:leading-relaxed">
                        {" "} {highlightText}
                    </span>
                </div>
            </div>
        );
    };
    return (

        <div className="w-full h-full bg-black overflow-x-hidden">
            <div className="lg:max-h-screen max-md:min-h-fit lg:h-fit w-full flex flex-col items-center justify-center lg:pt-[15vh] max-md:pt-[8vh]"
                style={{
                    backgroundImage: `url(${OurTechHeroSection})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}>

                <FeaturesSection
                    title="Our Technology"
                    subtitle="We are Transparent About Our Technology"
                    description="Understand how our process works and what we are doing to"
                    highlightText="skyrocket your ecommerce brand's success."
                />

                <div className="w-11/12 h-full flex flex-row lg:gap-[10vw] items-start mt-[5vh] justify-center max-md:flex-col max-md:gap-[5vh]">
                    {/* Animate this section to come from the left */}
                    <motion.div
                        initial={{ x: -1000 }}
                        animate={{ x: 0 }}
                        transition={{ duration: 1 }}
                        className="w-fit grid grid-cols-2 max-md:grid-cols-2 gap-4 min-h-full"
                    >
                        {algorithmsData.map((algorithm) => (
                            <button
                                key={algorithm.algorithmsId}
                                onClick={() => handleAlgorithmButtonClick(algorithm)}
                                className={`Resources-Algorithms-Section-Left-Button bg-[#0C0C0C] rounded-[12px] text-white text-left flex flex-col max-md:flex-row h-fit lg:min-h-[188px] max-md:h-[73px] items-start lg:p-8 max-md:p-4 w-full lg:gap-8 lg:mx-7 shadow-lg max-md:justify-around max-md:items-center max-md:gap-4
                        ${selectedAlgorithm && selectedAlgorithm.algorithmsId === algorithm.algorithmsId ? "selected" : ""}`}
                            >
                                <img src={algorithm.algorithmsIcon} className="max-md:w-[40px] max-md:h-[40px] lg:w-[60px] lg:h-[60px]" />
                                <p className="max-md:text-[13px]">
                                    {algorithm.algorithmsTitle}
                                </p>
                            </button>
                        ))}
                    </motion.div>

                    {/* Animate this section to come from the right */}
                    <motion.div
                        initial={{ x: 1000 }}
                        animate={{ x: 0 }}
                        transition={{ duration: 1 }}
                        className="lg:w-1/2 h-full"
                    >
                        {selectedAlgorithm ? (
                            <>
                                <p className="lg:text-[28px] max-md:text-[19px]">
                                    {selectedAlgorithm.algorithmsTitle}
                                </p>
                                <p className="lg:text-[13px] xl:text-[19px] max-md:text-[13px]">
                                    {selectedAlgorithm.algorithmsDescription}
                                </p>
                            </>
                        ) : (
                            <>
                                <p>{t("No algorithm selected")}</p>
                                <p>{t("Please click on an algorithm to see its details.")}</p>
                            </>
                        )}
                    </motion.div>
                </div>
            </div>

            {/* Animate this section to come from the bottom */}
            <motion.div
                id="about-us-section"
                className="flex flex-col w-full items-center justify-center lg:mt-[10vh]"
                initial={{ y: 1000 }}
                animate={{ y: 0 }}
                transition={{ duration: 1 }}
            >
                <FeaturesSection
                    title="Team"
                    subtitle="About Roasberry"
                    description="Discover how our expertise and innovative solutions"
                    highlightText="empower your business to reach new heights."
                />

                <div className="aboutUs-Container-Second-Row">
                    <div className="aboutUsHeaderFirstRow-RightColumn-Box">
                        <p className=" max-md:text-[13px]">
                            {t(
                                "We are an international marketing intelligence SAAS (Software as a Service) company on a mission to provide cutting-edge solutions for the global marketing industry. While we have a strong presence in the United States and Turkey, our reach extends far beyond these borders."
                            )}
                        </p>
                    </div>
                    <div className="aboutUsHeaderFirstRow-RightColumn-Box">
                        <p className=" max-md:text-[13px]">
                            {t(
                                "Our core expertise lies in services such as Pixel Tracking and Data Analysis, essential components of modern marketing strategies. We cater to businesses seeking innovative ways to optimize their marketing efforts, enhance their customer targeting, and drive more effective campaigns."
                            )}
                        </p>
                    </div>

                    <div className="aboutUsHeaderFirstRow-RightColumn-Box">
                        <p className=" max-md:text-[13px]">
                            {t(
                                "In today's dynamic and data-driven business environment, our solutions empower companies to harness the power of data analytics and pixel tracking to gain valuable insights into consumer behavior, campaign performance, and market trends. We are also committed to promoting ESG (Environmental, Social, and Governance) principles and EDI (Equity, Diversity, and Inclusion) in the marketing field, ensuring that our clients align with ethical and socially responsible practices."
                            )}
                        </p>
                    </div>
                    <div className="aboutUsHeaderFirstRow-RightColumn-Box">
                        <p className=" max-md:text-[13px]">
                            {t(
                                "Our dedication to innovation, ESG, and EDI, combined with our tailored services that cater to the unique demands of markets worldwide, allows us to keep our clients ahead of the competition and make informed decisions to maximize their ROI. We are firmly committed to staying at the forefront of marketing intelligence technology, continuously evolving our offerings to meet the ever-changing needs of our clients. This commitment to excellence, innovation, and customer satisfaction sets us apart as a trusted partner in the world of marketing intelligence."
                            )}
                        </p>
                    </div>
                </div>
            </motion.div >

            <BottomFooter />
            {isPopupOpen && <ScheduleDemoPopup onClose={togglePopup} />}
        </div >
    );
}

export default ResourcesTech;
