import roasberryVSTwImage from "./vsroasberryImages/roasberryVSTwImage.png";
import twIcon from "./vsroasberryImages/twIcon.png";

import roasberryVSNorthebeamImage from "./vsroasberryImages/roasberryVSNorthebeamImage.png";
import northbeamIcon from "./vsroasberryImages/northbeamIcon.png";

import roasberryVSPolarImage from "./vsroasberryImages/roasberryVSPolarImage.png"; // New image
import polarIcon from "./vsroasberryImages/polarIcon.png"; // New icon

import roasberryVSUpstackifiedImage from "./vsroasberryImages/roasberryVSUpstackifiedImage.png"; // New image
import upstackifiedIcon from "./vsroasberryImages/upstackifiedIcon.png"; // New icon

import roasberryVSKendallImage from "./vsroasberryImages/roasberryVSKendallImage.png"; // New image
import kendallIcon from "./vsroasberryImages/kendallIcon.png"; // New icon

import roasberryVSConvergeImage from "./vsroasberryImages/roasberryVSConvergeImage.png"; // New image
import convergeIcon from "./vsroasberryImages/convergeIcon.png"; // New icon

import checkIcon from "./vsroasberryImages/checkIcon.png";
import noIcon from "./vsroasberryImages/noIcon.png";

const roasberryVSData = [
    {
        dataId: 1,
        Title: "Roasberry vs Triple Whale",
        TitleText1: "With TripleWhale, you can keep track of your orders, use it for attributions. For example, you can see order history to view how customers interacted with your ads.",
        TitleText2: "With Roasberry you can filter the visitors to see how people who abandon checkout interact with your website and ads, just as you can see only orders with TripleWhale.",
        TitleImage: roasberryVSTwImage,
        dataTitle: "Triple Whale",
        tableIcon: twIcon,
        features: [
            { name: "Pixel Tracking", roasberry: "Yes", tripleWhale: "Yes" },
            { name: "Detailed Shopify Analytics", roasberry: "Yes", tripleWhale: "Yes" },
            { name: "Google Ads & Meta Ads Integration", roasberry: "Yes", tripleWhale: "Yes" },
            { name: "RFM Analysis and Customer Segments", roasberry: "Yes", tripleWhale: "Yes" },
            { name: "First Click, Last Click, Linear Attribution", roasberry: "Yes", tripleWhale: "Yes" },
            { name: "Visitor History for Converted Visits", roasberry: "Yes", tripleWhale: "Yes" },
            { name: "Visitor History for All Visits", roasberry: "Yes", tripleWhale: "No" },
            { name: "Time-Decay Attribution", roasberry: "Yes", tripleWhale: "No" },
            { name: "Position-Based Attribution", roasberry: "Yes", tripleWhale: "No" },
            { name: "Merchant Friendly Cost", roasberry: "Yes", tripleWhale: "No" }
        ]
    },
    {
        dataId: 2,
        Title: "Roasberry vs Northbeam",
        TitleText1: "Northbeam is an amazing tool for brands spending more than $2.5 M annually on paid advertising. It provides multiple attribution models, and also works for physical retail too.",
        TitleText2: "We designed Roasberry to be effective for brands in an affordable way while being accurate. Our digital fingerprint system tracks every visit - not just order - to provide you insights on not only purchases, but also on abandoned checkouts, pageViews and many more. We provide all the information for approximately 1/10th of the price.",
        TitleText3: "You can use time decay attribution model to check your conversion cycles, or switch between 6 different attribution models to use the best and most suitable one.",
        TitleImage: roasberryVSNorthebeamImage,
        dataTitle: "Northbeam",
        tableIcon: northbeamIcon,
        features: [
            { name: "Pixel Tracking", roasberry: "Yes", northbeam: "Yes" },
            { name: "Detailed Shopify Analytics", roasberry: "Yes", northbeam: "Yes" },
            { name: "Google Ads & Meta Ads Integration", roasberry: "Yes", northbeam: "Yes" },
            { name: "RFM Analysis and Customer Segments", roasberry: "Yes", northbeam: "No" },
            { name: "First Click, Last Click, Linear Attribution", roasberry: "Yes", northbeam: "Yes" },
            { name: "Visitor History for Converted Visits", roasberry: "Yes", northbeam: "Yes" },
            { name: "Visitor History for All Visits", roasberry: "Yes", northbeam: "No" },
            { name: "Time-Decay Attribution", roasberry: "Yes", northbeam: "No" },
            { name: "Position-Based Attribution", roasberry: "Yes", northbeam: "No" },
            { name: "Merchant Friendly Cost", roasberry: "Yes", northbeam: "No" }
        ]
    },
    {
        dataId: 3,
        Title: "Roasberry vs Polar Analytics",
        TitleText1: "Polar Analytics is a great tool for marketers with many integrations from different platforms. A platform providing real-time insights, pixel tracking, AI assistanceship, different attribution models, a user-friendly interface, and quick integrations sounds incredible yet familiar doesn’t it? We provide all of the above at a fraction of the cost, with dedicated customer support to answer all your needs.",
        // TitleText2: "Wouldn't you like to make your ad tracking more effective with Roasberry?",
        TitleImage: roasberryVSPolarImage,
        dataTitle: "Polar Analytics",
        tableIcon: polarIcon,
        features: [
            { name: "Pixel Tracking", roasberry: "Yes", polarAnalytics: "Yes" },
            { name: "Detailed Shopify Analytics", roasberry: "Yes", polarAnalytics: "Yes" },
            { name: "Google Ads & Meta Ads Integration", roasberry: "Yes", polarAnalytics: "Yes" },
            { name: "RFM Analysis and Customer Segments", roasberry: "Yes", polarAnalytics: "Yes" },
            { name: "First Click, Last Click, Linear Attribution", roasberry: "Yes", polarAnalytics: "Yes" },
            { name: "Visitor History for Converted Visits", roasberry: "Yes", polarAnalytics: "Yes" },
            { name: "Visitor History for All Visits", roasberry: "Yes", polarAnalytics: "Yes" },
            { name: "Time-Decay Attribution", roasberry: "Yes", polarAnalytics: "No" },
            { name: "Position-Based Attribution", roasberry: "Yes", polarAnalytics: "No" },
            { name: "Merchant Friendly Cost", roasberry: "Yes", polarAnalytics: "No" }
        ]
    },
    {
        dataId: 4,
        Title: "Roasberry vs Upstackified",
        TitleText1: "Roasberry provides insights on purchases, such as abandoned items, most viewed pages, on which pages users land and leave, how their flow looks so that you won’t need GA4 data, or you will not have to rely on Meta Pixel while Upstackified will push back data to Meta and Google.",
        TitleText2: "Roasberry has a built-in RFM analysis, which you can upload to platforms based on campaign goals. Plus, you can see how every visitor moves through your website to get insights on conversions, and how customers react on your website.",
        TitleImage: roasberryVSUpstackifiedImage,
        dataTitle: "Upstackified",
        tableIcon: upstackifiedIcon,
        features: [
            { name: "Pixel Tracking", roasberry: "Yes", upstackified: "Yes" },
            { name: "Detailed Shopify Analytics", roasberry: "Yes", upstackified: "Yes" },
            { name: "Google Ads & Meta Ads Integration", roasberry: "Yes", upstackified: "Yes" },
            { name: "Visitor History for Converted Visits", roasberry: "Yes", upstackified: "Yes" },
            { name: "Merchant Friendly Cost", roasberry: "Yes", upstackified: "Yes" },
            { name: "First Click, Last Click, Linear Attribution", roasberry: "Yes", upstackified: "No" },
            { name: "RFM Analysis and Customer Segments", roasberry: "Yes", upstackified: "No" },
            { name: "Visitor History for All Visits", roasberry: "Yes", upstackified: "No" },
            { name: "Time-Decay Attribution", roasberry: "Yes", upstackified: "No" },
            { name: "Position-Based Attribution", roasberry: "Yes", upstackified: "No" }
        ]
    },
    {
        dataId: 5,
        Title: "Roasberry vs Kendall",
        TitleText1: "Kendall provides more than just order connection. It connects with any attribution platform to pull analytics from Google and Meta and provides insights based on their models.",
        TitleText2: "However, Roasberry is more flexible with its RFM segmentation, deep visitor history analytics, and other attribution models.",
        TitleImage: roasberryVSKendallImage,
        dataTitle: "Kendall",
        tableIcon: kendallIcon,
        features: [
            { name: "Pixel Tracking", roasberry: "Yes", kendall: "Yes" },
            { name: "Detailed Shopify Analytics", roasberry: "Yes", kendall: "Yes" },
            { name: "Google Ads & Meta Ads Integration", roasberry: "Yes", kendall: "Yes" },
            { name: "Visitor History for Converted Visits", roasberry: "Yes", kendall: "Yes" },
            { name: "Merchant Friendly Cost", roasberry: "Yes", kendall: "Yes" },
            { name: "First Click, Last Click, Linear Attribution", roasberry: "Yes", kendall: "No" },
            { name: "RFM Analysis and Customer Segments", roasberry: "Yes", kendall: "No" },
            { name: "Visitor History for All Visits", roasberry: "Yes", kendall: "No" },
            { name: "Time-Decay Attribution", roasberry: "Yes", kendall: "No" },
            { name: "Position-Based Attribution", roasberry: "Yes", kendall: "No" }
        ]
    },
    {
        dataId: 6,
        Title: "Roasberry vs Converge",
        TitleText1: "Converge provides solutions for integrating multiple ad platforms to track user interactions and conversions across different digital channels. It focuses on optimizing ad spend and understanding customer behavior in real-time.",
        TitleText2: "Roasberry, on the other hand, offers a more focused approach with in-depth attribution models and precise tracking of customer interactions for both online purchases and engagement.",
        TitleImage: roasberryVSConvergeImage,
        dataTitle: "Converge",
        tableIcon: convergeIcon,
        features: [
            { name: "Pixel Tracking", roasberry: "Yes", converge: "Yes" },
            { name: "Detailed Shopify Analytics", roasberry: "Yes", converge: "Yes" },
            { name: "Google Ads & Meta Ads Integration", roasberry: "Yes", converge: "Yes" },
            { name: "Visitor History for Converted Visits", roasberry: "Yes", converge: "Yes" },
            { name: "Merchant Friendly Cost", roasberry: "Yes", converge: "Yes" },
            { name: "First Click, Last Click, Linear Attribution", roasberry: "Yes", converge: "No" },
            { name: "RFM Analysis and Customer Segments", roasberry: "Yes", converge: "No" },
            { name: "Visitor History for All Visits", roasberry: "Yes", converge: "No" },
            { name: "Time-Decay Attribution", roasberry: "Yes", converge: "No" },
            { name: "Position-Based Attribution", roasberry: "Yes", converge: "No" }
        ]
    }

];

export default roasberryVSData;
