import React, { useEffect, useState } from "react";
import { motion } from 'framer-motion';
import "./Product.css";
import "./pricing.css";
import { BsFillCheckCircleFill } from "react-icons/bs";
import PricingPageLeftColumnImage from "./Images/Pricing.png";
import BottomFooter from "./bottomfooter";
import cancelIcon from "./Images/cancelIcon.png";
import Instantly25 from "./Images/Instantly25.png";
import EarlyBirdIcon from "./Images/EarlyBirdIcon.png";
import ScheduleDemoPopup from "./ScheduleDemoPopup";
import pricingD1 from "./Images/PricingD1.png";
import pricingD2 from "./Images/PricingD2.png";
import pricingD3 from "./Images/PricingD3.png";
import freeTrialButtonNew from "./Images/freeTrialButtonNew.png";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import DiscreteSliderLabel from "./CustomSlider.js";
import searchIcon from "./Images/searchIcon.png";
import adSpentIcon from "./Images/adSpentIcon.png";
import faqIcon from "./Images/faqIcon.png";
import faqPlusIcon from "./Images/faqPlusIcon.png";
import faqMinusIcon from "./Images/faqMinusIcon.png";
import homePageLastImage from "./Images/homePageLastImage.png";
import favicon from "./Images/formIcon.png";
import pricingLine from "../src/pricingLine.png";
import princingNewBackground from "../src/princingNewBackground.png";
import BackgroundImage from "../src/princingNewBackground.png";
import { FaCheck } from "react-icons/fa"; // Assuming you're using react-icons library
import AnimatedSection from "./AnimatedSection";
import menuOpenArrow from "./SVG/menuOpenArrow.svg";
import featuresCheckIcon from "./SVG/featuresCheckIcon.svg";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import faqplus from "./SVG/faqplus.svg";
function Newpricing() {
  const TypewriterEffect = ({ text = "" }) => {
    const [displayedText, setDisplayedText] = useState(""); // Initialize as empty string

    useEffect(() => {
      if (text && text.length >= 0) {
        let i = 0; // Start from the first character (index 0)
        const interval = setInterval(() => {
          setDisplayedText(text.slice(0, i + 1)); // Set the slice of the text from 0 to i
          i++;
          if (i >= text.length) {
            clearInterval(interval); // Clear interval when done
          }
        }, 100); // 100ms delay between each letter

        return () => clearInterval(interval); // Cleanup interval on component unmount
      }
    }, [text]);

    return (
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {displayedText}
      </motion.span>
    );
  };

  const { t } = useTranslation();
  const [showFeatures, setShowFeatures] = useState(false);
  const toggleFeatures = () => {
    setShowFeatures(!showFeatures);
  };
  const PricingCard = ({ name, title, price, features }) => (
    <div className="max-md:w-full overflow-hidden shadow-lg rounded-lg border border-[#222222] bg-[#0C0C0C] text-white p-6 lg:mb-4 max-md:pl-6">
      <div className="mb-4">
        <h3 className="text-[24px] font-bold">{name}</h3>
        <p className="text-[14px] font-base whitespace-pre-line"> {title}</p>
      </div>
      <hr className="border-[#222222] border-1 mb-4" />
      <div className="mb-4 flex flex-row items-baseline">
        <p className="text-[32px] font-bold">
          ${isAnnual ? price * 10 : price}
        </p>
        <p className="text-[16px]">/ {isAnnual ? "year" : "month"}</p>
      </div>
      <button
        className="bg-[#CF0A54] hover:bg-pink-700 text-white font-bold py-2 px-4 rounded"
        onClick={() =>
          (window.location.href = "https://roasberrydashboard.web.app/#/Login")
        }
      >
        Start 14-Day Free Trial
      </button>

      {/* Button for small screens (max-md) to toggle features */}
      <div className="md:hidden flex flex-row w-full mx-auto justify-between items-start my-2" onClick={toggleFeatures}>
        <p>Plan includes: </p>
        <img
          src={menuOpenArrow}
          className={`w-[18px] h-[18px] transition-transform duration-300 ${showFeatures ? 'rotate-180' : ''}`}
          alt="Toggle Arrow"
        />
      </div>

      {/* Features list */}
      <ul className={`lg:mt-6 space-y-2 ${showFeatures ? 'block' : 'hidden'} md:block`}> {/* Show based on state for small screens, always visible for large screens */}
        {features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <span className="text-[#CF0A54] mr-2">
              <img src={featuresCheckIcon} className="w-[24px] h-[24px]" />
            </span> {feature}
          </li>
        ))}
      </ul>
    </div>
  );

  const plans = [
    {
      name: "Starter Tier",
      title: "For businesses with annual revenue \nup to $500K.",
      price: 149,
      features: [
        "All-in-One Dashboard",
        "Pixel Tracking",
        "Advanced Marketing Analytics",
        "Automated Reporting",
        "Customer Journeys",
        "Visitor Insights/Logs",
        "Unlimited Devices",
        "Customer Support",
      ],
    },
    {
      name: "Growth Tier",
      title: "For businesses with annual revenue \nbetween $500K and $1M.",
      price: 199,
      features: [
        "All-in-One Dashboard",
        "Pixel Tracking",
        "Advanced Marketing Analytics",
        "Automated Reporting",
        "Customer Journeys",
        "Visitor Insights/Logs",
        "Unlimited Devices",
        "Customer Support",
      ],
    },
    {
      name: "Scale Tier",
      title: "For businesses with annual revenue \nbetween $1M and $5M.",
      price: 299,
      features: [
        "All-in-One Dashboard",
        "Pixel Tracking",
        "Advanced Marketing Analytics",
        "Automated Reporting",
        "Customer Journeys",
        "Visitor Insights/Logs",
        "Unlimited Devices",
        "Customer Support",
      ],
    },
    {
      name: "Enterprise Tier",
      title: "For businesses with annual revenue \nover $5M.",
      price: 499,
      features: [
        "All-in-One Dashboard",
        "Pixel Tracking",
        "Advanced Marketing Analytics",
        "Automated Reporting",
        "Customer Journeys",
        "Visitor Insights/Logs",
        "Unlimited Devices",
        "Customer Support",
      ],
    },
  ];

  const faqData = [
    {
      question: t("pricingFAQQuestion1"),
      answer: t("pricingFAQAnswer1"),
    },
    {
      question: t("pricingFAQQuestion2"),
      answer: t("pricingFAQAnswer2"),
    },
    {
      question: t("pricingFAQQuestion3"),
      answer: t("pricingFAQAnswer3"),
    },
    {
      question: t("pricingFAQQuestion4"),
      answer: t("pricingFAQAnswer4"),
    },
    {
      question: t("pricingFAQQuestionRefund"),
      answer: t("pricingFAQAnswerRefund"),
    },
    {
      question: t("pricingFAQQuestionRevenue"),
      answer: t("pricingFAQAnswerRevenue"),
    },
    {
      question: t("pricingFAQQuestionSupport"),
      answer: t("pricingFAQAnswerSupport"),
    },
    {
      question: t("pricingFAQQuestionChanges"),
      answer: t("pricingFAQAnswerChanges"),
    },
  ];


  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  const [open, setOpen] = useState();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);

  const buttonClickHandler = (buttonName) => {
    setSelectedButton(buttonName === selectedButton ? null : buttonName);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const [price, setPrice] = useState(149);
  const [isTypeformOpen, setIsTypeformOpen] = useState(true);

  const handleOpenTypeform = () => {
    setIsTypeformOpen(true);
  };

  const handleCloseTypeform = () => {
    setIsTypeformOpen(false);
  };
  const [clickedButton, setClickedButton] = useState("monthly");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    const dropdown = document.getElementById("dropdown");
    dropdown.classList.toggle("hidden");
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [billingInterval, setBillingInterval] = useState("monthly");
  const [isAnnuallyAdjusted, setIsAnnuallyAdjusted] = useState(false);

  const [monthlyPrice, setMonthlyPrice] = useState(149); // Add monthly price state

  const handleTopButtonClick = (interval) => {
    if (interval === "annually" && !isAnnuallyAdjusted) {
      setPrice(price * 10); // Adjust the price for annual billing
      setIsAnnuallyAdjusted(true);
    } else if (interval === "monthly" && isAnnuallyAdjusted) {
      setPrice(monthlyPrice); // Set price to monthly price
      setIsAnnuallyAdjusted(false);
    }
    setBillingInterval(interval);
  };
  const [isAnnual, setIsAnnual] = useState(false);
  const FeaturesSection = ({ title, subtitle, description, highlightText }) => {
    return (
      <div className="w-11/12 max-md:w-11/12 h-full max-md:h-full flex-col justify-start items-center gap-2 inline-flex my-4">
        {/* "Our Features" Button */}
        <div className="px-4 bg-[#e30b5c] rounded-[51.43px] justify-center items-center inline-flex">
          <div className="text-white text-lg font-medium font-['Raleway'] leading-[41.66px] max-md:text-[18px]">
            {title}
          </div>
        </div>

        {/* "Services We Offer" Heading */}
        <div className="text-center text-white text-[49px] max-md:text-4xl font-normal font-['Raleway'] leading-[58.80px] max-md:leading-[43.20px]">
          {subtitle}
        </div>

        {/* Description Text */}
        <div className="text-center">
          <span className="text-[#ececec] max-md:text-[#f1f1f1] text-xl max-md:text-[13px] font-normal font-['Raleway'] leading-7 max-md:leading-[20.80px]">
            {description}
          </span>
          <span className="text-[#56ee99] text-2xl m max-md:text-[13px] font-normal font-['Times New Roman'] italic leading-[33.60px] max-md:leading-relaxed">
            {" "} {highlightText}
          </span>
        </div>
      </div>
    );
  };
  return (
    <div className="bg-black w-full h-full">
      <div
        className="flex w-full min-h-screen h-full max-md:h-fit pt-[10vh] text-left justify-center lg:pt-[15vh] "
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      >
        <div className="flex flex-col w-11/12 items-center justify-center gap-10 max-md:flex-col max-md:items-center max-md:h-fit">
          <div className="h-[212px] max-md:h-fit flex-col justify-start items-center gap-2 inline-flex max-md:gap-5">
            <div className="px-4 bg-[#e30b5c] rounded-[51.43px] justify-center items-center inline-flex">
              <div className="text-white text-lg font-medium font-['Raleway'] capitalize leading-[41.66px]">
                <TypewriterEffect text="All tiers include All Features" />
              </div>
            </div>
            <div className="self-stretch text-center">
              <span className="text-white text-5xl font-normal font-['Raleway'] lg:leading-[57.60px] max-md:text-[40px]">
                Revenue-Based Pricing,
              </span>
              <span className="text-[#56ee99] text-5xl font-normal font-['Raleway'] lg:leading-[57.60px] max-md:text-[40px]">
                {" "} <TypewriterEffect text="Full Access" />
              </span>
            </div>
            <div className="self-stretch text-center">
              <span className="text-white text-[19px] font-normal font-['Raleway'] lg:leading-[34.20px] max-md:text-[13px]">
                We appreciate your interest in our services. Before getting started, please note that we offer four different packages, all with the same features.
              </span>
              <br />
              <span className="text-white text-[19px] font-semibold font-['Raleway'] underline lg:leading-[34.20px] max-md:text-[13px]">
                The pricing is based on your estimated total revenue over the last 12 months in USD.

              </span>
              <br />
              <span className="text-white text-[19px] font-normal font-['Raleway'] lg:leading-[34.20px] max-md:text-[13px]">
                To proceed, select a package that best fits your revenue. If you have any questions, our team is ready to help.
              </span>
            </div>
          </div>

          <div className="max-md:w-full max-md:min-h-fit">
            <div className="flex justify-center mb-4 max-lg:w-full max-lg:items-center max-lg:justify-center">
              <div className="flex items-center space-x-4 max-lg:w-full max-lg:items-center max-lg:justify-center max-lg:flex-col">
                <p className="text-white">
                  Pay Annually and Get 2 Months Free!
                </p>
                <div className="bg-[#0C0C0C] rounded-full p-1 ">
                  <button
                    onClick={() => setIsAnnual(true)}
                    className={`py-2 px-4 rounded-full ${isAnnual
                      ? "bg-[#CF0A54] text-white"
                      : "bg-[#0C0C0C] text-gray-400"
                      }`}
                  >
                    Annually
                  </button>
                  <button
                    onClick={() => setIsAnnual(false)}
                    className={`py-2 px-4 rounded-full ${!isAnnual
                      ? "bg-[#CF0A54] text-white"
                      : "bg-[#0C0C0C] text-gray-400"
                      }`}
                  >
                    Monthly
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-around gap-5 max-lg:flex-col max-lg:gap-3 max-lg:items-center max-md:w-full">
              {plans.map((plan, index) => (
                <PricingCard
                  key={index}
                  name={plan.name}
                  title={plan.title}
                  price={plan.price}
                  features={plan.features}
                  isAnnual={isAnnual}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <AnimatedSection>
        <div className="h-fit max-md:h-full w-11/12 mx-auto flex flex-col items-center justify-center bg-black  my-[5vh]   ">
          <FeaturesSection
            title="FAQ"
            subtitle="Frequently Asked Questions"
            description="Your go-to guide for answers to commonly asked questions"
            highlightText="about our account info and fees."
          />
          <div className="flex flex-row max-md:flex-col text-white max-md:w-full mx-auto lg:gap-[5vw] ">
            <div className="flex flex-col items-start max-md:hidden">
              <p className="lg:text-5xl">Account</p>
              <p className="lg:text-5xl">& Payment</p>
            </div>
            <div className="flex flex-col w-full ">
              {faqData.map((faq, index) => (
                <Accordion
                  key={index}
                  open={open === index + 1}
                  className={`mb-4 lg:p-4 max-md:px-2 h-fit max-md:justify-center max-md:items-center rounded-[16px] border border-[#222222] bg-[#0C0C0C] text-white font-regular max-md:text-[16px] ${open === index + 1 ? "border-[#CF0A54] border-opacity-5 bg-[#340214] " : ""}`}
                >
                  <AccordionHeader
                    onClick={() => handleOpen(index + 1)}
                    className="border-b-0 flex items-center justify-between transition-colors max-md:items-start max-md:h-[88px]"
                  >
                    <p className="text-base font-medium max-md:w-10/12">                    {faq.question}
                    </p>
                    <img
                      src={open === index + 1 ? faqplus : faqplus}
                      className={`w-[24px] ml-auto transition-transform duration-300 ${open === index + 1 ? 'rotate-45' : ''
                        }`}
                      alt="Toggle Icon"
                    />
                  </AccordionHeader>
                  <AccordionBody className="pt-0 text-white font-normal  mt-3">
                    <p className="text-sm max-md:text-[13px]">
                      {faq.answer}
                    </p>
                  </AccordionBody>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      </AnimatedSection>

      <BottomFooter />
      {isPopupOpen && <ScheduleDemoPopup onClose={togglePopup} />}
    </div>
  );
}

export default Newpricing;